import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Button,
  CircularProgress,
  Paper,
  Box,
  Alert,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
  Snackbar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import './App.css';

const Input = styled('input')({
  display: 'none',
});

const POLL_INTERVAL = 5000; // 5 seconds
const API_BASE_URL = 'https://chatcutasr-cze3fwcpbzenhda2.southeastasia-01.azurewebsites.net'; // Change this URL as needed

export default function App() {
  const [videoFile, setVideoFile] = useState(null);
  const [currentJobId, setCurrentJobId] = useState(null);
  const [jobIdInput, setJobIdInput] = useState('');
  const [transcription, setTranscription] = useState(null);
  const [rawTranscriptionData, setRawTranscriptionData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState({
    videoUpload: false,
    audioConversion: false,
    transcription: false,
    transcriptionKaldi: false,
    jobStatus: false,
    captioning: false,
  });
  const [jobStatus, setJobStatus] = useState(null);
  const [showJobDialog, setShowJobDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [captionVideoFile, setCaptionVideoFile] = useState(null);
  const [captionJsonFile, setCaptionJsonFile] = useState(null);
  const [showCaptionDialog, setShowCaptionDialog] = useState(false);
  

   // Poll job status when we have a currentJobId
   useEffect(() => {
    let intervalId;
    
    const pollStatus = async () => {
      if (!currentJobId) return;
      
      try {
        const response = await fetch(`${API_BASE_URL}/job_status/${currentJobId}`);
        if (!response.ok) throw new Error('Failed to fetch job status');
        
        const data = await response.json();
        setJobStatus(data);
        
        if (data.status === 'completed' && data.result) {
          if (data.result.transcription) {
            setTranscription(data.result.transcription);
            setRawTranscriptionData(data.result);
          } else if (data.result.frame_captions) {
            setRawTranscriptionData(prev => ({
              ...prev,
              frame_captions: data.result.frame_captions
            }));
            setSnackbar({ open: true, message: 'Captions generated successfully!' });
          }
          clearInterval(intervalId);
        } else if (data.status === 'failed') {
          setError(data.error || 'Job failed');
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error('Error polling job status:', error);
        setError('Error checking job status');
        clearInterval(intervalId);
      }
    };
    
    if (currentJobId) {
      pollStatus(); // Poll immediately
      intervalId = setInterval(pollStatus, POLL_INTERVAL);
    }
    
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [currentJobId]);

  const handleFileChange = (e) => {
    setVideoFile(e.target.files[0]);
    setCurrentJobId(null);
    setTranscription(null);
    setRawTranscriptionData(null);
    setError(null);
    setJobStatus(null);
  };

  const copyJobId = () => {
    navigator.clipboard.writeText(currentJobId);
    setSnackbar({ open: true, message: 'Job ID copied to clipboard!' });
  };


  const lookupJob = async () => {
    if (!jobIdInput) {
      setError("Please enter a job ID");
      return;
    }

    setLoading(prev => ({ ...prev, jobStatus: true }));
    setError(null);

    try {
      const response = await fetch(`${API_BASE_URL}/job_status/${jobIdInput}`);
      if (!response.ok) throw new Error('Job not found');
      
      const data = await response.json();
      setCurrentJobId(jobIdInput);
      setJobStatus(data);
      setShowJobDialog(false);
      
      if (data.status === 'completed' && data.result) {
        if (data.result.transcription) {
          setTranscription(data.result.transcription);
          setRawTranscriptionData(data.result);
        }
      }
    } catch (error) {
      setError('Error finding job: ' + error.message);
    }

    setLoading(prev => ({ ...prev, jobStatus: false }));
  };

  const convertToAudio = async () => {
    if (!videoFile) {
      setError("Please upload a video first.");
      return;
    }
  
    setLoading((prev) => ({ ...prev, audioConversion: true }));
    setError(null);
  
    const formData = new FormData();
    formData.append('file', videoFile);
  
    try {
      const response = await fetch(`${API_BASE_URL}/convert_internal`, {
        method: 'POST',
        body: formData,
      });
 
  
      if (!response.ok) {
        throw new Error('Audio conversion failed');
      }
  
      const data = await response.json();
      setCurrentJobId(data.job_id);
      setShowJobDialog(true); // Show dialog with job ID
    } catch (error) {
      console.error('Error:', error);
      setError('Error during audio conversion');
    }
  
    setLoading((prev) => ({ ...prev, audioConversion: false }));
  };

  const handleTranscription = async (type) => {
    if (!jobStatus || jobStatus.status !== 'completed') {
      setError("Please wait for audio conversion to complete first.");
      return;
    }
  
    const endpoint = type === 'chatcut' ? 'transcribe_internal' : 'transcribe_kaldi';
    setLoading((prev) => ({ ...prev, [type === 'chatcut' ? 'transcription' : 'transcriptionKaldi']: true }));
    setError(null);
    
    try {
      const response = await fetch(`${API_BASE_URL}/${endpoint}`, {
        method: 'POST',
      });
  
      if (!response.ok) {
        throw new Error(`${type} transcription failed`);
      }
  
      const data = await response.json();
      setCurrentJobId(data.job_id);
      setShowJobDialog(true); // Show dialog with new job ID
    } catch (error) {
      console.error('Error:', error);
      setError(`Error during ${type} transcription`);
    }
  
    setLoading((prev) => ({ ...prev, [type === 'chatcut' ? 'transcription' : 'transcriptionKaldi']: false }));
  };

  const handleCaptionAndMerge = async () => {
    if (!captionVideoFile || !captionJsonFile) {
      setError("Please upload both video and JSON files.");
      console.log("Caption and merge failed: Missing files", { captionVideoFile, captionJsonFile });
      return;
    }
  
    console.log("Starting caption and merge process", {
      videoFile: captionVideoFile.name,
      jsonFile: captionJsonFile.name
    });
  
    setLoading(prev => ({ ...prev, captioning: true }));
    setError(null);
  
    const formData = new FormData();
    formData.append('video', captionVideoFile);
    formData.append('transcription', captionJsonFile);
  
    try {
      console.log("Sending request to caption_and_merge endpoint...");
      const response = await fetch(`${API_BASE_URL}/caption_and_merge`, {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`Caption and merge failed with status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("Caption and merge job created successfully", {
        jobId: data.job_id,
        message: data.message
      });
  
      setCurrentJobId(data.job_id);
      
      // Show both alert and snackbar messages
      setSnackbar({ 
        open: true, 
        message: `Process started - Job ID: ${data.job_id} (copied to clipboard)`
      });
      
      // Automatically copy the job ID to clipboard
      navigator.clipboard.writeText(data.job_id).then(() => {
        console.log(`Job ID ${data.job_id} copied to clipboard`);
      }).catch(err => {
        console.warn("Failed to copy job ID to clipboard:", err);
      });
      
    } catch (error) {
      console.error("Caption and merge process failed:", {
        error: error.message,
        stack: error.stack
      });
      setError('Error during caption and merge process');
      setLoading(prev => ({ ...prev, captioning: false }));
    }
  };
  
  // Also add logging to the job status polling
  useEffect(() => {
    let intervalId;
    
    const pollStatus = async () => {
      if (!currentJobId) return;
      
      try {
        console.log(`Polling status for job ${currentJobId}...`);
        const response = await fetch(`${API_BASE_URL}/job_status/${currentJobId}`);
        
        if (!response.ok) throw new Error('Failed to fetch job status');
        
        const data = await response.json();
        console.log(`Job ${currentJobId} status update:`, {
          status: data.status,
          hasResult: !!data.result,
          hasTranscription: data.result?.transcription ? true : false,
          hasFrameCaptions: data.result?.frame_captions ? true : false
        });
        
        setJobStatus(data);
        
        if (data.status === 'completed' && data.result) {
          if (data.result.transcription) {
            console.log(`Job ${currentJobId} completed with transcription`);
            setTranscription(data.result.transcription);
            setRawTranscriptionData(data.result);
          } else if (data.result.frame_captions) {
            console.log(`Job ${currentJobId} completed with frame captions`);
            setRawTranscriptionData(prev => ({
              ...prev,
              frame_captions: data.result.frame_captions
            }));
            setSnackbar({ open: true, message: 'Captions generated successfully!' });
          }
          clearInterval(intervalId);
        } else if (data.status === 'failed') {
          console.error(`Job ${currentJobId} failed:`, data.error);
          setError(data.error || 'Job failed');
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error(`Error polling status for job ${currentJobId}:`, {
          error: error.message,
          stack: error.stack
        });
        setError('Error checking job status');
        clearInterval(intervalId);
      }
    };
    
    if (currentJobId) {
      console.log(`Starting status polling for job ${currentJobId}`);
      pollStatus(); // Poll immediately
      intervalId = setInterval(pollStatus, POLL_INTERVAL);
    }
    
    return () => {
      if (intervalId) {
        console.log(`Stopping status polling for job ${currentJobId}`);
        clearInterval(intervalId);
      }
    };
  }, [currentJobId]);

  const downloadJobResult = async (jobId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/download_result/${jobId}`, {
        method: 'GET',
      });
  
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `result_${jobId}.json`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Failed to download result');
      }
    } catch (error) {
      console.error('Error downloading result:', error);
      setError('Failed to download result');
    }
  };

  const downloadRawTranscriptionJSON = () => {
    if (rawTranscriptionData) {
      const dataStr = JSON.stringify(rawTranscriptionData, null, 2);
      const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      const exportFileDefaultName = `transcription_${currentJobId}.json`;

      const linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    }
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>Video Transcription App</Typography>
          <Button color="inherit" onClick={() => setShowJobDialog(true)}>
            Look Up Job
          </Button>
          <Button color="inherit" onClick={() => setShowCaptionDialog(true)}>
            Video Captioning
          </Button>
        </Toolbar>
      </AppBar>

      <Container maxWidth="md" sx={{ mt: 4 }}>
        {/* Job Status Section */}
        {currentJobId && (
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Box display="flex" alignItems="center" gap={2}>
              <Typography variant="subtitle1">
                Current Job: {currentJobId}
              </Typography>
              <Tooltip title="Copy Job ID">
                <IconButton onClick={copyJobId} size="small">
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              <Typography variant="subtitle1" sx={{ ml: 'auto' }}>
                Status: {jobStatus?.status || 'Unknown'}
              </Typography>
            </Box>
          </Paper>
        )}

        {/* File Upload Section */}
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <label htmlFor="contained-button-file">
              <Input
                accept="video/mp4"
                id="contained-button-file"
                type="file"
                onChange={handleFileChange}
              />
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                {videoFile ? videoFile.name : 'Select MP4 File'}
              </Button>
            </label>
            <Button
              variant="contained"
              onClick={convertToAudio}
              disabled={!videoFile || loading.audioConversion}
            >
              {loading.audioConversion ? 'Converting...' : 'Convert to Audio'}
            </Button>
          </Box>
        </Paper>

        {/* Transcription Buttons */}
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Box display="flex" justifyContent="space-around">
            <Button
              variant="contained"
              onClick={() => handleTranscription('chatcut')}
              disabled={!jobStatus || jobStatus.status !== 'completed' || loading.transcription}
            >
              {loading.transcription ? 'Transcribing with ChatCut...' : 'Transcribe with ChatCut'}
            </Button>
            <Button
              variant="contained"
              onClick={() => handleTranscription('kaldi')}
              disabled={!jobStatus || jobStatus.status !== 'completed' || loading.transcriptionKaldi}
            >
              {loading.transcriptionKaldi ? 'Transcribing with Kaldi...' : 'Transcribe with Kaldi'}
            </Button>
          </Box>
        </Paper>

        {/* Loading Indicator */}
        {(loading.audioConversion || loading.transcription || loading.transcriptionKaldi || loading.jobStatus || loading.captioning) && (
          <Box display="flex" justifyContent="center" alignItems="center" height={200}>
            <CircularProgress />
          </Box>
        )}

        {/* Error Display */}
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>
        )}

        {/* Transcription Results */}
        {transcription && Array.isArray(transcription) && transcription.length > 0 && (
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>Transcription Result</Typography>
            <List>
              {transcription.map((segment, index) => (
                <React.Fragment key={index}>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={`Segment ${index + 1}`}
                      secondary={
                        <>
                          <Typography component="span" variant="body2" color="text.primary">
                            {segment.segment.text}
                          </Typography>
                          <br />
                          <Typography component="span" variant="caption">
                            Speaker: {segment.segment.speaker} |
                            Time: {segment.segment.start.toFixed(2)}s - {segment.segment.end.toFixed(2)}s
                          </Typography>
                          <Box sx={{ mt: 1 }}>
                            {segment.words.map((word, wordIndex) => (
                              <Chip
                                key={wordIndex}
                                label={`${word.word} (${word.start.toFixed(2)}s-${word.end.toFixed(2)}s)`}
                                size="small"
                                sx={{ m: 0.5 }}
                                title={`Speaker: ${word.speaker}, Score: ${word.score.toFixed(2)}`}
                              />
                            ))}
                          </Box>
                        </>
                      }
                    />
                  </ListItem>
                  {index < transcription.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <Button
                variant="contained"
                onClick={downloadRawTranscriptionJSON}
                startIcon={<DownloadIcon />}
              >
                Download Raw JSON
              </Button>
            </Box>
          </Paper>
        )}

        {/* Job Lookup Dialog */}
        <Dialog open={showJobDialog} onClose={() => setShowJobDialog(false)}>
          <DialogTitle>
            {currentJobId ? "Current Job ID" : "Look Up Job"}
          </DialogTitle>
          <DialogContent>
            {currentJobId ? (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body1" gutterBottom>
                  Job ID:
                </Typography>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="h6" component="pre" sx={{ fontFamily: 'monospace' }}>
                    {currentJobId}
                  </Typography>
                  <IconButton onClick={copyJobId} size="small">
                    <ContentCopyIcon />
                  </IconButton>
                </Box>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  Status: {jobStatus?.status || 'Unknown'}
                </Typography>
                {jobStatus?.status === 'completed' && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => downloadJobResult(currentJobId)}
                    sx={{ mt: 2 }}
                  >
                    Download Result
                  </Button>
                )}
              </Box>
            ) : (
              <Box sx={{ mt: 2 }}>
                <TextField
                  label="Enter Job ID"
                  variant="outlined"
                  fullWidth
                  value={jobIdInput}
                  onChange={(e) => setJobIdInput(e.target.value)}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            {currentJobId ? (
              <Button onClick={() => setShowJobDialog(false)}>Close</Button>
            ) : (
              <>
                <Button onClick={() => setShowJobDialog(false)}>Cancel</Button>
                <Button onClick={lookupJob} variant="contained">
                  Look Up
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>

        {/* Video Captioning Dialog */}
        <Dialog open={showCaptionDialog} onClose={loading.captioning ? undefined : () => setShowCaptionDialog(false)}>
          <DialogTitle>Upload Files for Captioning</DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <Input
                accept="video/mp4"
                id="caption-video-upload"
                type="file"
                onChange={(e) => setCaptionVideoFile(e.target.files[0])}
              />
              <label htmlFor="caption-video-upload">
                <Button variant="contained" component="span" startIcon={<CloudUploadIcon />}>
                  Upload MP4 Video
                </Button>
              </label>
              {captionVideoFile && (
                <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                  {captionVideoFile.name}
                </Typography>
              )}
            </Box>

            <Box sx={{ mt: 2 }}>
              <Input
                accept="application/json"
                id="caption-json-upload"
                type="file"
                onChange={(e) => setCaptionJsonFile(e.target.files[0])}
              />
              <label htmlFor="caption-json-upload">
                <Button variant="contained" component="span" startIcon={<CloudUploadIcon />}>
                  Upload JSON Transcription
                </Button>
              </label>
              {captionJsonFile && (
                <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                  {captionJsonFile.name}
                </Typography>
              )}
            </Box>

            {/* Job Status Alert - Shows as soon as processing starts */}
            {(currentJobId || loading.captioning) && (
              <Alert 
                severity="info" 
                sx={{ mt: 2 }}
                action={
                  currentJobId && (
                    <IconButton
                      aria-label="copy"
                      color="inherit"
                      size="small"
                      onClick={copyJobId}
                    >
                      <ContentCopyIcon fontSize="inherit" />
                    </IconButton>
                  )
                }
              >
                {loading.captioning ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CircularProgress size={20} />
                    <Typography variant="body2">Processing... Please wait</Typography>
                  </Box>
                ) : (
                  <>
                    <Typography variant="subtitle2" gutterBottom>
                      Job ID (Save this to check status later):
                    </Typography>
                    <Box
                      component="pre"
                      sx={{
                        p: 1,
                        mt: 1,
                        bgcolor: 'grey.100',
                        borderRadius: 1,
                        fontFamily: 'monospace',
                        fontSize: '0.875rem',
                        wordBreak: 'break-all'
                      }}
                    >
                      {currentJobId}
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Status: {jobStatus?.status || 'Unknown'}
                    </Typography>
                  </>
                )}
              </Alert>
            )}

            {/* Download Button - Shows when job is completed */}
            {jobStatus?.status === 'completed' && (
              <Box sx={{ mt: 2 }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => downloadJobResult(currentJobId)}
                  startIcon={<DownloadIcon />}
                >
                  Download Result
                </Button>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => setShowCaptionDialog(false)}
              disabled={loading.captioning}
            >
              Close
            </Button>
            <Button 
              onClick={handleCaptionAndMerge}
              variant="contained"
              disabled={!captionVideoFile || !captionJsonFile || loading.captioning}
            >
              {loading.captioning ? 'Processing...' : 'Start Captioning'}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          message={snackbar.message}
        />
      </Container>
    </>
  );
}
